<template>
  <div id="subpage-content">
    <ProductDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarIntegration") }}</h1>
        <div id="int-about-text">
          <img height="100" width="150" :src="getImageUrlbyName('Serv1')" />
          <a id="pad">{{ $t("message.IntGeoloc") }}</a>
          <div class="geoloc-container">
            <div class="geoloc-elem">
              <a id="pad" href="https://gurtam.com/ru">1. Wialon</a>
              <a id="pad" href="https://scout-gps.ru/">2. СКАУТ</a>
              <a id="pad" href="https://locarus.ru/">3. Локарус</a>
              <a
                id="pad"
                href="https://www.dynafleetonline.com/AS/authorize?response_type=code&client_id=dfol&redirect_uri=https://dynafleetonline.com/fm/portal/app/authenticate&scope=dfol-fm&state=anticsrf2011756957662728401"
                >4. Dynafleet Volvo</a
              >
              <a id="pad" href="https://www.scania.com/">5. Scania</a>
              <a id="pad" href="https://www.tk-nav.ru/sys-mon-ag/sys-mon"
                >6. Автограф</a
              >
              <a id="pad" href="http://sputnik-auto.ru/">7. Спутник Авто </a>
            </div>
            <div class="geoloc-elem">
              <a id="pad" href="https://nika-glonass.ru/WebMap/"
                >8. НИКА-ГЛОНАСС
              </a>
              <a id="pad" href="https://www.omnicomm.ru/">9. Omnicomm </a>
              <a id="pad" href="https://www.traccar.org/">10. Traccar </a>
              <a id="pad" href="https://www.fort-telecom.ru/products"
                >11. Форт-Телеком
              </a>
              <a id="pad" href="https://www.geliossoft.ru/"
                >12. Аруснави (Гелиос)
              </a>
              <a id="pad" href="https://www.gpshome.ru/track_xml"
                >13. GPSHome (Globalsat)</a
              >
              <a id="pad" href="https://itob.ru/">14. ITOB</a>
            </div>
            <div class="geoloc-elem">
              <a id="pad" href="http://ends-samara.ru/">15. ЕНДС </a>
              <a id="pad" href="https://www.navixy.ru/">16. Navixy </a>
              <a id="pad" href="http://tol-trans.ru/">17. ТОЛЬЯТТИТРАНС </a>
              <a
                id="pad"
                href="https://www.antor.ru/products/sistema-onlayn-monitoringa-transporta-glonass-gps/"
                >18. ANTOR MonitorMaster
              </a>
              <a id="pad" href="http://rnt.ru/ru/solutions/detail.php?ID=74"
                >19. РНТ</a
              >
              <a id="pad" href="http://starcom-spb.ru/">20. Starcom</a>
              <a id="pad" href="https://teltonika-iot-group.com/"
                >21. TAVL WEB</a
              >
            </div>
          </div>
          <a id="pad">{{ $t("message.IfHaveNo") }}</a>
        </div>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn"
                >{{ $t("message.BuyService") }}
              </v-btn>
              <div id="history-card-text">
                {{ $t("message.MakeRequest") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import Feedback from "../../../components/Feedback";
import ProductDrawer from "../../../views/Navigations/Products/ProductDrawer";
export default {
  name: "Integration",
  components: {
    ProductDrawer,
    Feedback,
  },
};
</script>
<style>
.geoloc-container {
  display: flex;
}
.geoloc-elem {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
@media (max-width: 500px) {
  .geoloc-container {
    flex-direction: column;
  }
}
</style>